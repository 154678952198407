<template>
  <div>
    <slot></slot>
  </div>
</template>
<script lang="ts">
export default defineComponent({
  props: {
    from: {
      type: String as PropType<string | null>,
      required: false,
      default: null,
    },
    logContents: {
      type: Object as PropType<any>,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      log: '',
    }
  },
  mounted() {
    if (this.$slots.default) {
      const test = this.$slots.default()
      this.log = `${this.$route.path}@${test[0].type.name}`
    }
  },
})
</script>
